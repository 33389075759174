<template>
	<div id="Notice">
		<div class="title">
			<i class="el-icon-bell"></i>
			消息中心
		</div>
		<el-collapse v-model="activeNames" @change="handleChange">
			<el-collapse-item v-for="item in MessageList" :name="item.id" :key="item.id">
				<template slot="title">
					<div class="tit">
						{{item.messageType=="PUBLIC"?"【公告】":"【消息】"}}
						{{item.title}}
					</div>

					<span class="el-icon-info" v-if="item.readStatus == 'NOT-READ'">
						未读消息
					</span>
				</template>
				<div class="message" @click="toDetail(item)">
					<p>{{`用户：${item.userName} `}}</p>
					<p>{{`操作：${item.title}`}}</p>
					<p>{{`标题：${item.fileName}`}}</p>
					<p>{{`发布时间：${item.createTime}`}}</p>
				</div>
			</el-collapse-item>

			<el-pagination background layout="prev, pager, next" :total="total" :hide-on-single-page="true"
				@current-change="handleCurrentChange">
			</el-pagination>
		</el-collapse>
	</div>
</template>

<script>
	import {
		getMyMessage, // 或许消息列表
		updateStatus // 修改消息状态
	} from '@/request/apiPerCenter.js';
	export default {
		name: 'Notice',
		components: {},
		data() {
			return {
				activeNames: [],
				MessageList: [],
				pageSize: 10,
				pageNum: 1,
				total: 0
			}
		},
		created() {
			this.getMyMessageList();
		},
		mounted() {},
		watch: {
			"$route.query": {
				immediate: true, // 第一次的数据，也要当做是一种变化
				handler(val) {
					// 执行代码部分
					this.activeNames = [val.id]
					this.handleChange([val.id])
				},
			},
		},
		methods: {
			handleCurrentChange(val) {
				this.pageNum = val;
				this.getMyMessageList();
			},

			getMyMessageList() {
				let data = {
					pageNum: this.pageNum,
					pageSize: this.pageSize
				}
				getMyMessage(data).then(res => {
					if (res.code == 200) {
						this.MessageList = res.rows;
						this.total = res.total;

					}
				})
			},

			// 跳转到详情
			toDetail(data) {
				// console.log(JSON.parse(JSON.stringify(data)))
				if (data.fileId) {
					// 文档详情
					this.$router.push({
						name: 'NetDisk',
						query: {
							id: data.fileId
						}
					})
				} else if (data.priceId) {
					// 报价单详情
					this.$router.push({
						name: 'history',
						query: {
							id: data.priceId
						}
					})
				}
			},

			handleChange(val) {
				// console.log(val)

				this.MessageList.filter((v) => val.some((val) => val == v.id)).map((item) => {
					if (item.readStatus == "NOT-READ") {
						updateStatus({
							messageId: item.id
						}).then(res => {
							if (res.code == 200) {
								this.$store.state.msgInfo++;
								item.readStatus = "READ";
							}
						})
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	#Notice {
		min-height: 100vh;
		padding: 50px;
		background-color: #f4f4f4;

		>.title {
			font-size: 20px;
			margin-bottom: 20px;
		}

		/deep/.el-collapse {
			border-radius: 10px;
			max-width: 1000px;
			// padding: 20px 40px;
			// background-color: #fff;

			.el-collapse-item {
				margin-bottom: 20px;
				border-radius: 10px;
				overflow: hidden;

				.el-collapse-item__header {
					padding: 5px 10px;

					.tit {
						font-size: 16px;
						margin: 0;
						margin-right: 20px;
					}

					span {
						color: #F56C6C;
					}
				}

				.el-collapse-item__content {
					padding: 10px 20px;

					.message {
						p {
							font-size: 14px;
							line-height: 2;
							cursor: pointer;
						}
					}
				}
			}


		}

		/deep/.el-pagination {
			margin: 40px 0;
			text-align: center;

			.number.active {
				background-color: #682EDC;
				color: #fff;
			}

			.number,
			.btn-quickprev,
			.btn-quicknext {
				color: #333;
			}
		}
	}
</style>